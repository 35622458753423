 import Vue from 'vue'
import VueRouter from 'vue-router'
import { getStore, setStore } from '@/utils/storage'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
 if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
 return originalPush.call(this, location).catch(err => err)
}



 const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/login',
    meta:{showPath:""},
    name: '登陆',
    hidden:true,//不在导航列表中显示
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Login.vue')
  },
  {
    path: '/register',
    meta:{showPath:""},
    name: '注册',
    hidden:true,//不在导航列表中显示
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Register.vue')
  },
  {
    path: '/forget',
    meta:{showPath:""},
    name: '忘记密码',
    hidden:true,//不在导航列表中显示
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Forget')
  },
  {
    path: '/info',
    meta:{showPath:""},
    name: '完善信息',
    hidden:true,//不在导航列表中显示
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Info')
  },
  {
    path: '/company',
    meta:{showPath:""},
    name: '加入企业',
    hidden:true,//不在导航列表中显示
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Company')
  },
  {
    path: '/create',
    meta:{showPath:""},
    name: '创建企业',
    hidden:true,//不在导航列表中显示
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Create')
  },
  {
    path:'/',
    name: "操作台",
    meta:{showPath:"/dashboard"},
    leafThree:false,//有三级路由
    component: () => import(/* webpackChunkName: "about" */ '../views/layout/index'),
    icon:require('../img/router/icon1.png'),
    icon_dark:require('../img/router/icon1-dark.png'),
    redirect: "/dashboard",
    permissions:"admin",
    children:[
      {
        path: "/dashboard",
        name: "操作台",
        meta:{showPath:"/dashboard"},
        // hidden:true,//不在导航列表中显示
        permissions:"index_index",
        component: resolve => require(["@/views/dashboard/index"], resolve),
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
      },
      {
        path: "/dashboard/zbcz",
        meta:{showPath:"/dashboard"},
        name: "准备出租",
        hidden:true,//不在导航列表中显示
        permissions:"go_rent_car",
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/zbcz"], resolve),
      },
      {
        path: "/dashboard/qxzc",
        meta:{showPath:"/dashboard"},
        name: "取消出租",
        permissions:"cancel_rent_car",
        hidden:true,//不在导航列表中显示
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/qxzc"], resolve)

      },
      {
        path: "/dashboard/clcs",
        meta:{showPath:"/dashboard"},
        name: "车辆出售",
        permissions:"webapp_tcarinfo_sale",
        hidden:true,//不在导航列表中显示
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/clcs"], resolve)

      },
      {
        path: "/dashboard/clcs/:state/detail/:id",
        meta:{showPath:"/dashboard"},
        name: "出售详情",
        permissions:"webapp_tcarinfo_sale",
        hidden:true,//不在导航列表中显示
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/detail/clcs"], resolve)

      },
      {
        path: "/dashboard/zc",
        meta:{showPath:"/dashboard"},
        name: "租车",
        permissions:"rent_car",
        hidden:true,//不在导航列表中显示
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/zc"], resolve)

      },
      {
        path: "/dashboard/zc/detail/:id",
        meta:{showPath:"/dashboard"},
        name: "租车详情",
        permissions:"rent_car",
        hidden:true,//不在导航列表中显示
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/detail/zc"], resolve)
      },
      {
        path: "/dashboard/position",
        meta:{showPath:"/dashboard"},
        name: "职位管理",
        permissions:"webapp_tjob_get",
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/position"], resolve)
      },
      {
        path: "/dashboard/customer",
        meta:{showPath:"/dashboard"},
        name: "客户",
        permissions:"webapp_tcustomer_get",
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/customer"], resolve)
      },
      {
        path: "/dashboard/insurance",
        meta:{showPath:"/dashboard"},
        name: "保险提醒",
        permissions:"webapp_tcustomer_get",
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/insurance/index"], resolve)
      },
      {
        path: "/dashboard/inspection",
        meta:{showPath:"/dashboard"},
        name: "车辆年检",
        permissions:"webapp_tcustomer_get",
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/inspection"], resolve)
      },
      {
        path: "/dashboard/maintain",
        meta:{showPath:"/dashboard"},
        name: "车辆维修",
        permissions:"webapp_tcustomer_get",
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/maintain"], resolve)
      },
      {
        path: "/dashboard/upkeep",
        meta:{showPath:"/dashboard"},
        name: "车辆维修",
        permissions:"webapp_tcustomer_get",
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/upkeep"], resolve)
      },
      {
        path: "/dashboard/sign",
        meta:{showPath:"/dashboard"},
        name: "签约记录",
        permissions:"webapp_tcustomer_get",
        icon:require('../img/router/icon1.png'),
        icon_dark:require('../img/router/icon1-dark.png'),
        component: resolve => require(["@/views/dashboard/sign"], resolve)
      },
    ]
  },
  {
    path: "/vehicle/clk",
    meta:{showPath:"/vehicle"},
    name: "车辆管理",
    icon:require('../img/router/icon2.png'),
    icon_dark:require('../img/router/icon2-dark.png'),
    component: () => import(/* webpackChunkName: "about" */ '../views/layout/index'),
    redirect: "/vehicle/clk",
    permissions:"webapp_tcustomer_get",
    children: [
      {
        path: "/vehicle/clk",
        meta:{showPath:"/vehicle"},
        name: "车辆管理",
        permissions:"webapp_tcustomer_get",
        component: resolve => require(["@/views/dashboard/clk"], resolve),
        icon:require('../img/router/icon2.png'),
        icon_dark:require('../img/router/icon2-dark.png'),
      }
    ]
  },
   // {
   //   name: "销售管理",
   // },
   {
     path:"/financial",
     meta:{showPath:"/financial"},
     name: "财务管理",
     icon:require('../img/router/icon7.png'),
     icon_dark:require('../img/router/icon7-dark.png'),
     component: () => import(/* webpackChunkName: "about" */ '../views/layout/index'),
     permissions:"admin",
     children: [
       {
         path: "/financial",
         meta:{showPath:"/financial"},
         name: "财务管理",
         component: resolve => require(["@/views/financial/index"], resolve),
         icon:require('../img/router/icon7.png'),
         icon_dark:require('../img/router/icon7-dark.png'),
       },
       {
         path: "/financial/ws/:id",
         meta:{showPath:"/financial"},
         name: "未收款详情",
         component: resolve => require(["@/views/detail/financial/ws"], resolve),
         icon:require('../img/router/icon7.png'),
         icon_dark:require('../img/router/icon7-dark.png'),
       },
       {
         path: "/financial/ss/:id",
         meta:{showPath:"/financial"},
         name: "实收款详情",
         component: resolve => require(["@/views/detail/financial/ss"], resolve),
         icon:require('../img/router/icon7.png'),
         icon_dark:require('../img/router/icon7-dark.png'),
       }
       ,
       {
         path: "/financial/wf/:id",
         meta:{showPath:"/financial"},
         name: "未付款详情",
         component: resolve => require(["@/views/detail/financial/wf"], resolve),
         icon:require('../img/router/icon7.png'),
         icon_dark:require('../img/router/icon7-dark.png'),
       }
       ,
       {
         path: "/financial/sf/:id",
         meta:{showPath:"/financial"},
         name: "实付款详情",
         component: resolve => require(["@/views/detail/financial/sf"], resolve),
         icon:require('../img/router/icon7.png'),
         icon_dark:require('../img/router/icon7-dark.png'),
       }
     ]
   },
  {
    path: "/customer",
    meta:{showPath:"/customer"},
    name: "客户管理",
    component: () => import(/* webpackChunkName: "about" */ '../views/layout/index'),
    redirect: "/customer",
    permissions:"webapp_tcustomer_get",
    icon:require('../img/router/icon3.png'),
    icon_dark:require('../img/router/icon3-dark.png'),
    children: [
      {
        path: "/customer",
        meta:{showPath:"/customer"},
        name: "客户管理",
        permissions:"webapp_tcustomer_get",
        component: resolve => require(["@/views/customer/index"], resolve),
        icon:require('../img/router/icon3.png'),
        icon_dark:require('../img/router/icon3-dark.png'),
      }
    ]
  },

  {
    path: "/library",
    meta:{showPath:"/library"},
    name: "配置管理",
    icon:require('../img/router/icon6.png'),
    icon_dark:require('../img/router/icon6-dark.png'),
    component: () => import(/* webpackChunkName: "about" */ '../views/layout/index'),
    permissions:"webapp_tcarbrand_get",
    children: [
      {
        path: "/library",
        meta:{showPath:"/library"},
        name: "企业品牌型号库",
        component: resolve => require(["@/views/library/index"], resolve),
        icon:require('../img/router/icon6.png'),
        icon_dark:require('../img/router/icon6-dark.png'),
      }
    ]
  },
   {
     path: "/handle",
     meta:{showPath:"/handle"},
     name: "功能管理",
     leaf:true,//有二级路由
     icon:require('../img/router/icon4.png'),
     icon_dark:require('../img/router/icon4-dark.png'),
     component: () => import(/* webpackChunkName: "about" */ '../views/layout/index'),
     redirect: "/handle/company",
     permissions:"admin",
     children: [
       {
         path: "/handle/company",
         meta:{showPath:"/handle"},
         name: "所属企业",
         permissions:"webapp_tenterprise_get",
         icon:require('../img/router/icon4.png'),
         icon_dark:require('../img/router/icon4-dark.png'),
         component: resolve => require(["@/views/handle/company"], resolve)
       },
       {
         path: "/handle/member",
         meta:{showPath:"/handle"},
         name: "人员管理",
         permissions:"admin",
         icon:require('../img/router/icon4.png'),
         icon_dark:require('../img/router/icon4-dark.png'),
         component: resolve => require(["@/views/handle/member"], resolve)
       },
       {
         path: "/handle/batch",
         meta:{showPath:"/handle"},
         name: "批量上传",
         permissions:"admin",
         icon:require('../img/router/icon4.png'),
         icon_dark:require('../img/router/icon4-dark.png'),
         component: resolve => require(["@/views/handle/batch"], resolve)
       },
     ]
   },
  {
    path: "/own",
    meta:{showPath:"/own"},
    name: "个人中心",
    leaf:true,//有二级路由
    icon:require('../img/router/icon5.png'),
    icon_dark:require('../img/router/icon5-dark.png'),
    component: () => import(/* webpackChunkName: "about" */ '../views/layout/index'),
    redirect: "/own/changeInfo",
    permissions:"admin",
    children:[
      {
        path: "/own/changeInfo",
        meta:{showPath:"/own"},
        name: "个人信息",
        permissions:"admin",
        component: resolve => require(["@/views/own/info"], resolve),
        icon:require('../img/router/icon5.png'),
        icon_dark:require('../img/router/icon5-dark.png'),
      },
      {
        path: "/own/changePassword",
        meta:{showPath:"/own"},
        name: "修改密码",
        permissions:"admin",
        component: resolve => require(["@/views/own/password"], resolve),
        icon:require('../img/router/icon5.png'),
        icon_dark:require('../img/router/icon5-dark.png'),
      },
      {
        path: "/own/changePhone",
        meta:{showPath:"/own"},
        name: "修改手机号",
        permissions:"admin",
        component: resolve => require(["@/views/own/phone"], resolve),
        icon:require('../img/router/icon5.png'),
        icon_dark:require('../img/router/icon5-dark.png'),
      }
    ]
  },


]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let islogin = localStorage.getItem("access_token");
  if(to.path == "/login"){
    if(islogin && localStorage.getItem("companyId")){
      next('/dashboard');
    }else{
      next();
    }
  }else if(to.path == "/register" || to.path == "/info" || to.path == "/forget" || to.path == "/company"){
    next();
  }else{
    next();
  }
})


export default router
