/**
 * 对象转url参数
 * @param {*} data
 * @param {*} isPrefix
 */
function filter(str) { // 特殊字符转义
    str += ''; // 隐式转换
    str = str.replace(/%/g, '%25');
    str = str.replace(/\+/g, '%2B');
    str = str.replace(/ /g, '%20');
    str = str.replace(/\//g, '%2F');
    str = str.replace(/\?/g, '%3F');
    str = str.replace(/&/g, '%26');
    str = str.replace(/\=/g, '%3D');
    str = str.replace(/#/g, '%23');
    return str;
}

function queryParams(paramObj) {
    const sdata = [];
    for (let attr in paramObj) {
        sdata.push(`${attr}=${filter(paramObj[attr])}`);
    }
    return sdata.join('&');
};

function dealTime(value){
    if (value!=null){
        var date = new Date(value);
        let Y = date.getFullYear()

        let M = date.getMonth() + 1 - 0 >= 10 ? Number(date.getMonth()) + 1 : '0' + (Number(date.getMonth()) + 1)

        let D = date.getDate()

        let h = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()

        let m = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()

        let s = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()

        return Y + '-' + M + '-' + D
    }else{
        return
    }

}

function dealString(value){
    let date = value.split('')
    let year = date[0]+date[1]+date[2]+date[3]
    let month= date[4]+date[5]
    let day= date[6]+date[7]
    return year + '-' + month + '-' + day
}
export  default {
    filter,
    queryParams,
    dealTime,
    dealString
}
