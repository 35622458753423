import Vue from 'vue'
import Vuex from 'vuex'
import {getStore, setStore} from "@/utils/storage";
import {getUserInfo} from "@/utils/api/own";
import {getList } from "@/utils/api/author";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //身份列表
    identitys: [],
    permissions: [],
    userInfo: {},
    company: ''
  },
  getters:{
    getIdentity(state){
      state.identitys = getStore('identity')?JSON.parse(getStore('identity')):null
      return state.identitys
    },
    getPermissions(state){
      state.permissions = getStore('permissions')?JSON.parse(getStore('permissions')):null
      return state.permissions
    },
  },
  mutations: {

  },
  actions: {
    getUserStoreInfo(){
      getUserInfo().then(res=>{
        this.state.permissions = res.data.permissions
        this.state.userInfo = JSON.parse(JSON.stringify(res.data.sysUser))
        this.state.company= res.data.sysUser.enterpriseName
        this.state.identity= res.data.roleList[0]['roleName']
      })
      setTimeout(()=>{
      },3000)
    },
    // getPList(){
    //   getList().then(res=>{
    //     console.log(res)
    //     this.state.identitys= res.data.roleList
    //   })
    // }
  },
  modules: {

  }
})
