/**
 * @notice 权限管理
 * @author hhq
 */

import http from "../http"

//角色列表
export const getList =(params)=>{
    return http.postRegister('/app/tJurisdiction/list',params)
}
