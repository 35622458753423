/**
 * @notice 工具函数
 */
function renderSize(value) {
    if (null == value || value == "") {
        return "0 Bytes";
    }
    let unitArr = new Array(
        "Bytes",
        "KB",
        "MB",
        "GB",
        "TB",
        "PB",
        "EB",
        "ZB",
        "YB"
    );
    let index = 0;
    let srcsize = parseFloat(value);
    index = Math.floor(Math.log(srcsize) / Math.log(1024));
    let size = srcsize / Math.pow(1024, index);
    size = size.toFixed(2); //保留的小数位数
    return size + unitArr[index];
}
function fn(arr) {
    return arr.map((item, index) => {
        if (item.children.length > 0) {
            fn(item.children)
        } else {
            delete item.children
        }
        return item;
    })

}

export default {
    renderSize,
    fn
}
