import request from "@/utils/request";
import { Base64 } from 'js-base64';
import { filter } from '@/utils/helper';

// const base = 'http://192.168.1.18:9999/webapp'   // 本地///
// const base = '/api'


const getRequest = (url, params) => {
    let str = "test:test";
    let pass = "Basic " + Base64.encode(str);
    return request({
        method: 'get',
        // url: `${base}${url}`,
        url: url,
        params: params,
    })
}
const getRequestId = (url, params, id) => {

    return request({
        method: 'get',
        // url: `${base}${url}`,
        url: `${url}/${id}`,
        params: params,
    })
}
const postRequest = (url, params) => {
    return request({
        method: 'post',
        // url: `${base}${url}`,
        url: url,
        data: params,
    })
}


const putRequest = (url, params) => {
    return request({
        method: 'put',
        // url: `${base}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = ''
            for (const it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
        }],
        // headers: {
        //     'Content-Type': 'application/json',
        // }
    })
}

const deleteRequest = (url, params) => {
    // const accessToken = getStore('accessToken')
    return request({
        method: 'delete',
        // url: `${base}${url}`,
        url: url,
        params: params,
        // headers: {
        //     'accessToken': accessToken
        // }
    })
}
const deleteRequestId = (url, params, id) => {
    // const accessToken = getStore('accessToken')
    return request({
        method: 'delete',
        url: `${url}/${id}`,
        params: params,

    })
}

const importRequest = (url, params) => {
    // const accessToken = getStore('accessToken')
    return request({
        method: 'post',
        // url: `${base}${url}`,
        url: url,
        data: params,
        // headers: {
        //     'accessToken': accessToken
        // }
    })
}

const uploadFileRequest = (url, params) => {
    // const accessToken = getStore('accessToken')
    return request({
        method: 'post',
        // url: `${base}${url}`,
        url: url,
        params: params,
        headers: {
            'Content-Type': "multipart/form-data"
        }
    })
}

const postRegister = (url, params) => {
    let str = "test:test";
    let pass = "Basic " + Base64.encode(str);
    return request({
        method: 'post',
        // url: `${base}${url}`,
        url: url,
        data: params,
    })
}

const postLogin = (url, params) => {
    let str = "pig:pig";
    let pass = "Basic " + Base64.encode(str);
    return request({
        method: 'post',
        url: url,
        data: params,
        headers: {
            'Authorization': pass
        }
    })
}



const postUrlParams = (url, params) => {
    let str = "test:test";
    let pass = "Basic " + Base64.encode(str);
    let urlParams = ''
    for (let attr in params) {
        urlParams += attr + '=' + params[attr] + '&'
    }
    return request({
        method: 'post',
        // url: `${base}${url}`,
        url: url + '?' + urlParams,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}



//postUrl传参
const postDictDetail = (url, params) => {
    let str = "test:test";
    let path = ''

    return request({
        method: 'post',
        // url: `${base}${url}`,
        url: url + '?code=' + params.code,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

const postJson = (url, params)=> {
    let str = "test:test";
    let path = ''
    let pass = "Basic " + Base64.encode(str);

    return request({
        method: 'post',
        // url: `${base}${url}`,
        url: url + '?json=' + JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': pass
        }
    })
}

export default {
    getRequest,
    getRequestId,
    postRequest,
    putRequest,
    deleteRequest,
    deleteRequestId,
    importRequest,
    uploadFileRequest,
    postRegister,
    postLogin,
    postUrlParams,
    postDictDetail,
    postJson,

}
