// const config = () => {
//     return { baseUrl : "http://192.168.1.18:9999/webapp/"}
// }
let config=''
if(process.env.NODE_ENV=="production"){
     config = {
        baseUrl : "https://api-carlease-m.lixingpt.com/webapp"
    }
}else{
     config = {
        // baseUrl : "http://192.168.1.18:9999/webapp"
        // baseUrl : "http://192.168.1.8:9091/webapp"
        baseUrl : "http://192.168.0.230:9999/webapp"

    }
}

export default  config
