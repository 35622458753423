/**
 * @notice 个人使用
 * @author hhq
 */
import http from "../http"
/*控制台*/
//获取登录人权限
export const getJurisdiction =(params)=>{
    return http.postRegister('/ad/app/data/getJurisdiction',params)
}
//获取控制台
export const kzt =(params)=>{
    return http.postRegister('/ad/app/data/kzt',params)
}
/*操作记录*/
//获取操作记录列表
export const getLogList =(params)=>{
    return http.postRegister('/app/sysOperLog/list',params)
}
//获取操作人员记录列表
export const getLogUser=(params)=>{
    return http.postRegister('/app/sysOperLog/per/list',params)
}
/*权限控制器*/
//获取用户信息
export const getUserDetail=(params)=>{
    return http.postRegister('/app/user/getUserDetail',params)
}
export const getUserInfo=(params)=>{
    return http.getRequest('/ad/app/user/info',params)
}
//更新用户信息
export const updateDetail=(params)=>{
    return http.postRegister('/app/user/updateUserInfo',params)
}

// 注册后获取手机验证码
export const Sms=(params)=>{
    return http.postRequest('/app/tool/sms',params)
}
