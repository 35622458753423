import * as lodash from 'lodash'
import { Loading, Message, MessageBox, Notification } from 'element-ui'

const install = (Vue, opts = {}) =>{
    /* 全局加载层 */
    Vue.prototype.$baseLoading = (index, text) => {
        let loading
        if (!index) {
            loading = Loading.service({
                lock: true,
                text: text || '正在加载中...',
                background: 'hsla(0,0%,100%,.8)',
            })
        } else {
            loading = Loading.service({
                lock: true,
                text: text || '正在加载中...',
                spinner: 'vab-loading-type' + index,
                background: 'hsla(0,0%,100%,.8)',
            })
        }
        return loading
    }
    /* 全局Message */
    Vue.prototype.$baseMessage = (message, type,position=false) => {
        Message({
            offset: 60,
            showClose: true,
            message: message,
            type: type,
            center: position,
            dangerouslyUseHTMLString: true,
            duration: 3000,
        })
    }
}

if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
}

export default install
