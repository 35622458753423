import Vue from 'vue'
import axios from 'axios'
import {getStore, removeStore, setStore} from './storage'
import router from '../router/index'
import { Message } from 'element-ui'
import config from "@/utils/config";
import Cookies from 'js-cookie'
import qs from 'qs'
import { Base64 } from "js-base64";
// 统一请求路径前缀

const base = config.baseUrl   // 本地/
// const base = 'http://192.168.1.225:9999/webapp'   // 本地/
// const base = 'http://192.168.1.204:89'
// const base = '/api'   // 本地/
// const base='/'
// const base = config.url

// 跨域请求，允许保存cookie
axios.defaults.withCredentials = false;
let loadingInstance
// 超时设定

const instance = axios.create({
    baseURL: base,
    timeout: 15000,

})
// http request 拦截器

instance.interceptors.request.use(config => {
    if (getStore('access_token')) {
        config.headers['authorization'] = "Bearer " + getStore('access_token')
    }
    if (
        config.data &&
        config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
        config.data = qs.stringify(config.data)
    }

    // if (config.headers){
    //     config.headers['Content-Type']="multipart/form-data"
    // }
    // loadingInstance = Vue.prototype.$baseLoading()
    config.headers['usertype'] = "1";
    config.headers['type'] = "1";
    return config
}, err => {
    Message.error('请求超时')
    return Promise.reject(err)
})

// http response 拦截器
instance.interceptors.response.use(response => {
    if (loadingInstance) loadingInstance.close()
    const data = response.data

    // 根据返回的code值来做不同的处理(和后端约定)
    switch (data.code) {
        case 401:
            // 未登录 清除已登录状态
            Cookies.set('userInfo', '')
            // setStore('accessToken', '')
            removeStore('accessToken')
            if (router.history.current.name !== 'login') {
                if (data.msg !== null) {
                    Message.error(data.msg)
                } else {
                    Message.error('未知错误，请重新登录')
                }
                router.push('/login')
            }
            break
        case 403:
            // 没有权限
            if (data.msg !== null) {
                Message.error(data.msg)
            } else {
                Message.error('未知错误')
            }
            break
        case 10005:
            // 没有权限
            // if (data.msg !== null) {
            //     localStorage.clear()
            //     router.push('/login')
            // } else {
            //     Message.error('未知错误')
            // }
            localStorage.clear()
            router.push('/login')
            break
        case 500:
            // 错误
            if (data.msg !== null) {
                Message.error(data.msg)
            } else {
                Message.error('未知错误')
            }
            break
        default:
            return data
    }

    return data
}, (err) => {
    if (loadingInstance) loadingInstance.close()
    // 返回状态码不为200时候的错误处理
    if(err.response.status === 426){
        Message.error(err.response.data.msg)
        localStorage.clear()
        router.push('/login')
    }else if(err.response.status === 424){
        // Message.error(err.response.data.msg)
        localStorage.clear()
        router.push('/login')
    }else if(err.response.status === 401) {
        localStorage.clear()
        router.push('/login')
        Message.error(err.response.data.msg)
    }else{
        Message.error(err.toString())
    }
    return Promise.resolve(err)
})



export default instance




