import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'babel-polyfill'
import "./css/common.less"
import "./css/change.css"
import vab from "@/utils/vab";
import tool from "@/utils/function"
import axios from 'axios' //引入axios

Vue.config.productionTip = false
Vue.prototype.$axios = axios //把axios添加到vue原型
Vue.prototype.utils = tool
Vue.use(ElementUI);
Vue.use(vab)

Vue.prototype.goHome = function (){
  console.log(1)
  this.$router.push({name: "操作台"})
}

Vue.filter("showNumber",function (value){
  if (!value) return ''
  if (value.length > 32) {
    return value.slice(0,32) + '...'
  }
  return value
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
